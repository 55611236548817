const API_BASE_URL = '/api/v1/';

function createMethod(name) {
    return {
        url: `${API_BASE_URL}${name}`,
        name
    };
}

export const get_methods_new = {
    signup: createMethod('signup'),
    signin: createMethod('signin'),
    signout: createMethod('signout'),
    getUserData: createMethod('getUserData'),
    getSearchParamsHistory: createMethod('getSearchParamsHistory'),
    setNewContragent: createMethod('setNewContragent'),
    setUserDocumentStatus: createMethod('setUserDocumentStatus'),
    getComplexRateList: createMethod('getComplexRateList'),
    getRateList: createMethod('getRateList'),
    getRates: createMethod('getRates'),
    setPrefRequestData: createMethod('setPrefRequestData'),
    setRateAnalizeRequest: createMethod('setRateAnalizeRequest'),
    setCompleteDocRequest: createMethod('setCompleteDocRequest'),
    addClientRequest: createMethod('addClientRequest'),
    setClientGoodsQuestionnaire: createMethod('setClientGoodsQuestionnaire'),
    setContragentRateInfo: createMethod('setContragentRateInfo'),
    getSettingByName: createMethod('getSettingByName'),
    getReferenceData: createMethod('getReferenceData'),
    getMinRateJson: createMethod('getMinRateJson'),
    setNotifyStatus: createMethod('setNotifyStatus'),
    setNewThreeBestRateParams: createMethod('setNewThreeBestRateParams'),
    setActiveThreeBestRateParams: createMethod('setActiveThreeBestRateParams'),
    getThreeBestRateParams: createMethod('getThreeBestRateParams'),
    addContractFile: createMethod('addContractFile'),
    getHolidaysByCountry: createMethod('getHolidaysByCountry'),
    getTransporterInfoById: createMethod('getTransporterInfoById'),
    getRateByKey: createMethod('getRateByKey'),
    getRateStatisticByKey: createMethod('getRateStatisticByKey'),
    getShipScheduleByKey: createMethod('getShipScheduleByKey'),
    getComplexShipSchedule: createMethod('getComplexShipSchedule'),
    getAlternativeRoute: createMethod('getAlternativeRoute'),
    getAutoServiceInfo: createMethod('getAutoServiceInfo'),
    getAutoServiceInfoRegion: createMethod('getAutoServiceInfoRegion'),
    getApproximatePrice: createMethod('getApproximatePrice'),
    getSecurityServiceInfo: createMethod('getSecurityServiceInfo'),
    getServiceInfo: createMethod('getServiceInfo'),
    getComplexAdditionalExpences: createMethod('getComplexAdditionalExpences'),
    getAdditionalExpensesRate: createMethod('getAdditionalExpensesRate'),
    getClientGoodsQuestionnaire: createMethod('getClientGoodsQuestionnaire'),
    setOrderMark: createMethod('setOrderMark'),
    getOrderMarksTemplate: createMethod('getOrderMarksTemplate'),
    getNakInnList: createMethod('getNakInnList'),
    getCopyOrderAnalogRate: createMethod('getCopyOrderAnalogRate'),
    setFilesToOrder: createMethod('setFilesToOrder'),
    getNotPayedInvoices: createMethod('getNotPayedInvoices'),
    getClientReferenceData: createMethod('getClientReferenceData'),
    getOrder: createMethod('getOrder'),
    getActiveOrdersList: createMethod('getActiveOrdersList'),
    getArchOrdersList: createMethod('getArchOrdersList'),
    getFinDetailsByOrderId: createMethod('getFinDetailsByOrderId'),
    getAdditionalExpensesRateByOrdId: createMethod('getAdditionalExpensesRateByOrdId'),
    getBookingRequestComments: createMethod('getBookingRequestComments'),
    getCustomsRequestComments: createMethod('getCustomsRequestComments'),
    getClientRequestComments: createMethod('getClientRequestComments'),
    setClientRequestComment: createMethod('setClientRequestComment'),
    setClientCustomsRequestComment: createMethod('setClientCustomsRequestComment'),
    setCommentVizited: createMethod('setCommentVizited'),
    setMultiCommentVizited: createMethod('setMultiCommentVizited'),
    getClientRequestsList: createMethod('getClientRequestsList'),
    feedback: createMethod('feedback'),
    getServiceNews: createMethod('getServiceNews'),
    getHotNewsList: createMethod('getHotNewsList'),
    getClientReviewsList: createMethod('getClientReviewsList'),
    getNewsBody: createMethod('getNewsBody'),
    getArticleBodyJson: createMethod('getArticleBodyJson'),
    getVedexxFaq: createMethod('getVedexxFaq'),
    getOurTeam: createMethod('getOurTeam'),
    tripwires: createMethod('tripwires'),
    getReviewsList: createMethod('testimonials'), 
    faq: createMethod('faq'),
    getReviewsList: createMethod('testimonials'),
    news: createMethod('news'),
    getcompanybyinn: createMethod('getcompanybyinn'),
    getbankbybik: createMethod('getbankbybik')
};

export const get_methods = {
    auth: {
        uri: "?_url=/vedexx",
        name: "auth"
    },
    registerByKey:{
        uri: "?_url=/vedexx",
        name: "registerByKey"
    },
    
    checkKey: {
        uri: "?_url=/vedexx",
        name: "checkKey"
    },

    getRegisterLink:{
        uri: "?_url=/vedexx",
        name: "generateLink"
    },
    
    getRateListAlert: {
        uri: "?_url=/vedexx",
        name: "getRateListAlert",
    },
    
    getRoute: {
        uri: "?_url=/autodispetcher",
        name: "getRoute"
    },

    getCityList: {
        uri: "?_url=/autodispetcher",
        name: "getCityList"
    },
    
    getInshuranceCost: {
        uri: "?_url=/vedexx",
        name: "getInshuranceCost"
    },

    getCompanyByInn: {
        uri: "?_url=/getcompanybyinn/"
    },
    
    getBankByBik: {
        uri: "?_url=/getbankbybik/"
    },

    getCompareRateList:{
        uri: "?_url=/vedexx",
        name: "getCompareRateList"
    },

    getOrderMarksTemplate: {
        uri: "?_url=/vedexx",
        name: "getOrderMarksTemplate",
    },

    getFileByPath: {
        uri: "?_url=/files",
        name: "getfilebypath"
    },

    ftpposttest:{
        uri: "?_url=/ftpposttest",
        name: "ftpposttest"
    },

    getInfoByContainer: {
        uri: "?_url=/ed22",
        name: "GetInfoByContainer"
    },

    getProviderInfo: {
        uri: "?_url=/ed22",
        name: "GetProviderInfo"
    },

    getAltaInfo: {
        uri: "?_url=/ed22",
        name: "GetAltaInfo"
    },
};

export const set_methods = {
    setUpdateOrderRequest:{
        uri: "?_url=/vedexx",
        name: "setUpdateOrderRequest"
    },

    check: {
        uri: "?_url=/vedexx",
        name: "check"
    },
};

export const reference_list = {
    ClientVDRefList: {
        name: "clnt_vd_ref_company",
        items: []
    },
    
    ClientVDRefDeliveryTerms: {
        name: "clnt_vd_ref_delivery_terms",
        items: []
    },
    
    ClientVDRefUnitTypes: {
        name: "lgst_cont_types",
        items: []
    },
    
    lgst_place_from_wg: {
        name: "lgst_place_from_wg",
        items: []
    },
    
    lgst_place_to_wg: {
        name: "lgst_place_to_wg",
        items: []
    },
    
    lgst_alarm_class: {
        name: "lgst_place_to_wg",
        items: []
    },
    
    ClientVDRefPlaceFrom: {
        name: "places",
        items: []
    },

    ClientVDRefPlaceTo: {
        name: "dsplaces",
        items: []
    },

    ClientVDRefGoods: {
        name: "goods",
        items: []
    },

    ClientVDRefCustoms: {
        name: "ref_to_places",
        items: []
    },

    ClientVDRefDeliveryTypes: {
        name: "ref_shipping_types",
        items: []
    },

    ClientVDRefTaxRates: {
        name: "ref_tax_rate_prc_rate_request",
        items: []
    },

    ClientVDRefPorts: {
        name: "ports",
        items: []
    },

    ClientVDRefClients: {
        name: "clientvdrefclients",
        items: []
    },

    ClientVDRefClientsCommission:{
        name: 'clientvdrefclientscommission',
        items: []
    },

    ClientVDRefCurrency:{
        name: 'ref_freight_currency',
        items: []
    },
    
    LgstLineList:{
        name: 'lgst_line_list',
        items: []
    },
};


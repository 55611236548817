<template>
  <v-app>
    <component :is="layout">
      <router-view />
      <notifications position="top right" class="mt-notify" width="25%">
        <template slot="body" slot-scope="props">
          <div class="vue-notify" :class="'vue-notify_' + props.item.type">
            <div class="mt-notify-title flex justify-space-between">
              {{ props.item.title }}
              <img
                src="@/assets/img/icons/mdi/mdi-close-white.svg"
                @click="props.close"
              />
            </div>
            <div class="mt-notify-text">
              {{ props.item.text }}
            </div>
          </div>
        </template>
      </notifications>
    </component>
  </v-app>
</template>

<script>
import DefaultLayout from "./components/layouts/DefaultLayout";
import DarkLayout from "./components/layouts/DarkLayout";
import LightLayout from "./components/layouts/LightLayout";
import EmptyLayout from "./components/layouts/EmptyLayout";
import RatePageLayout from "./components/layouts/RatePageLayout.vue";

import { bus } from "@/router/bus";

export default {
  name: "App",

  components: {
    DefaultLayout,
    DarkLayout,
    LightLayout,
    EmptyLayout,
    RatePageLayout,
  },

  mounted() {
    setTimeout(() => {
      this.sendYandexLink();
    }, 2000);
  },

  computed: {
    layout() {
      return this.$route.meta.layout || "DarkLayout";
    },
  },

  watch: {
    "$route.query": {
      handler() {
        if (!!this.$_getsetting("token") && !!this.$_getsetting("is_auth")) {
          // this.checkKey()
        }
        this.sendYandexLink();
      },
      deep: true,
    },
  },

  methods: {
    sendYandexLink() {
      this.$metrika?.hit(this.$route.fullPath)
    },

    checkKey() {
      this.$postapi(this.$address + this.$setters.check.uri, {
        method: this.$setters.check.name,
        token: this.$_getsetting("token"),
      }).then(data => {
        if (data) {
          if (data.error == 0) {
            console.log(data.message);
          } else {
            this.$_removesetting("token");
            this.$_removesetting("user_id");
            this.$_removesetting("login");
            this.$_removesetting("email");
            this.$_removesetting("is_auth");
            this.$_removesetting("client_id");
            this.$_removesetting("fio");
            this.$_removesetting("clnt_name");
            this.$_removesetting("manager_email");
            this.$_removesetting("is_show_bm_messages");
            this.$_removesetting("manager_name");
            this.$_removesetting("manager_phone");
            this.$_removesetting("place_from");
            this.$_removesetting("place_to");
            this.$_removesetting("unit_code");
            this.$_removesetting("on_date");
            this.$forceUpdate();
            setTimeout(() => {
              bus.$emit("show_notify", {
                color: "warn",
                duration: 4500,
                notifytext:
                  "Время сессии истекло по причине отсутствия активности на сайте в течение 12 часов. Просим пройти авторизацию повторно.",
              });
            }, 400);
            bus.$emit("auth-update");
            this.gotoMain();
          }
        }
      });
    },

    gotoMain() {
      if (this.$route.name !== "MainPage") {
        this.$router.push("/");
      }
    },

    getNotifyClass(className) {
      return "vue-notification_" + className;
    },
  },
};
</script>

<style lang="scss">
.vue-notify {
  padding: 20px 20px;
  margin: 0 5px 5px;
  font-size: 12px;
  color: #ffffff;
  border-left: 5px solid black;
  background: #f48a06;
  border-left-color: #d37601;

  @media (max-width: 600px) {
    //min-height: 15vh;
    height: auto;
    width: 100% !important;
    padding: 20px 15px;
    border-radius: 10px;
  }

  &_warn {
    background: #f48a06;
    border-left-color: #d37601;
  }

  &_error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &_success {
    background: #42a85f;
    border-left-color: #378c4f;
  }
}

.mt-notify {
  margin-top: 0.4%;
  margin-right: 0.4%;
  border-radius: 0.02vw;
  color: #ffffff;

  @media (max-width: 600px) {
    border-radius: 10px;
    width: 100% !important;
  }
}

.dx-scrollbar-horizontal {
  height: 15px !important;
}
.dx-scrollable-scroll {
  height: 15px !important;
}

.mt-notify-text {
  font-size: 0.8vw;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.mt-notify-title {
  font-size: 1vw;
  font-weight: 600;
  display: flex;
  width: 100%;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.dx-widget {
  font-family: "Montserrat", sans-serif !important;
}
</style>

<script>
import { bus } from "../../router/bus";
import { reachYMGoal } from "@/utils/metrika";
import AppButton from '@/components/ui/AppButton.vue';
import AppInput from '@/components/ui/AppInput.vue';
import AppTextarea from '@/components/ui/AppTextarea.vue';

export default {
  name: "ContactForm.vue",
  components: {
    AppButton,
    AppInput,
    AppTextarea
  },

  props: {
    rate: {
      type: [Object, Array],
      required: false
    }
  },
  
  data: () => ({
    user_email: '',
    message: '',
    subject: null,
    current_href: null,
    
    emailRules: [
      v => !!v || 'Укажите email',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Похоже, что введенный адрес некорректен...'
      },
    ],
    
    textRules: [
      v => !!v || 'Укажите обращение',
    ],
  }),
  
  mounted() {
    bus.$on('updateEmail', (email) => {
      this.user_email = email;
    });

    if (this.rate) {
      this.current_href = 'https://vedexx.com/RatePage?key=' +
        this.rate.key_orig + '&on_date=' +
        this.rate.on_date + '&cid=' + this.$_getsetting('client_id')
    } else {
      this.current_href = location.href
    }
  },

  beforeDestroy() {
    bus.$off('updateEmail');
  },
  
  methods: {
    validate() {
      this.$refs.footerInput.onSubmit()
      let validInput = this.$refs.footerInput.validate(),
          validTextArea = this.$refs.footerTextArea.validate()

      if (validInput && validTextArea) {
        this.sendContactForm()
        this.addMetrics('Chat')
      }

    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    sendContactForm() {
      const uri = this.$api + this.$methods.feedback.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        bcc: '',
        cc: '',
        // rate_key: this.rate_key ? this.rate_key : '',
        to: this.$_getsetting('manager_email') ? this.$_getsetting('manager_email') : 'help@vedexx.com',
        subject: this.subject,
        type: 'email',
        body: 'Клиент: ' + this.$_getsetting('clnt_name') + `<p></p>` +
          'почта: ' + this.user_email + `<br/>` +
          'текст: ' + this.message + `<p></p>` +
          'ссылка: ' + this.current_href,
        atachments: [],
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.clearForm()
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message + ' Мы свяжемся с вами в ближайшее время'
          });
          this.clearForm()
          this.$emit('close')
        }
      });
    },
    
    clearForm() {
      this.message = ''
      this.$refs.form.resetValidation()
    }
  }
}

</script>

<template>
  <div>
    <!-- <div class="form-title">
      Форма для связи
    </div> -->
    <v-form
      ref="form"
      class="form footer-form"
      @submit.prevent="validate"
    >
      <AppInput
        v-model="user_email"
        :label="'Ваш email'"
        :placeholder="'Введите Вашу электронную почту'"
        :rules="emailRules"
        dark
        class="footer-form__input"
        ref="footerInput"
      />

      <AppTextarea
        v-model="message"
        :placeholder="'Ваше сообщение'"
        :rows="7"
        :rules="textRules"
        dark
        class="footer-form__area"
        ref="footerTextArea"
      />

      <div class="footer-form__bottom">
        <div class="form-link">или напишите нам в техническую поддержку
          <a href="mailto:help@vedexx.com">help@vedexx.com</a>
        </div>

        <AppButton
          :color="'orange'"
          :size="'medium'"
          :label="'Отправить'"
          class="footer-form__btn"
        />

      </div>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.form {
  max-width: 523px;
  width: 100%;


  @media (max-width: $tablet-width){
    max-width: 100%;
  }

  &-title {
    @include font-body-bold-1;

    display: none;
    margin: 30px 0 -5px;

  }
  
  &-input {
    @include font-description-0;
  }
  
  &-btn {
    @include font-description-semibold-0;

    display: flex;
    align-items: center;
    letter-spacing: 0.00892857em;
    text-transform: lowercase;
  }
  
  &-link {
    color: var(--bg-dark);

    @include font-body-bold-3;
  }
}

a {
  text-decoration: underline;
  color: var(--bg-dark) !important;
  
  &:hover {
    text-decoration: underline;
  }
}

.footer-form{
  &__bottom{
    display: flex;
    flex-direction: column-reverse;
    gap: 24px 0;
    margin-top: 24px;

    @media (max-width: $mobile-width){
      gap: 16px 0;
      margin-top: unset;
    }

    .form-link, a{
      color: #FFF !important;
    }

    .footer-form__btn{
      max-width: 130px;

      @media (max-width: $mobile-width){
        margin-top: 24px;
        max-width: 100%;
      }
    }
  }

  &__area{
    margin-top: 8px;

    textarea{
      padding: 8px;
    }
  }
}


</style>

<script>
import { bus } from "@/router/bus";
// import MainInput from "./MainInput";
import { validateInn } from "@/utils/validators";
import { reachYMGoal } from "@/utils/metrika";
import AppCheckbox from '@/components/ui/AppCheckbox.vue';
import AppInput from '@/components/ui/AppInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppLoader from '@/components/ui/AppLoader.vue';

export default {
  name: "LoginForm.vue",
  components: {
    // MainInput,
    AppCheckbox,
    AppInput,
    AppButton,
    AppLoader
  },

  props: {
    type: {
      type: String,
      required: false,
      default: () => "login",
      validator: (value) => ["login", "signup"].includes(value),
    }
  },

  data() {
    return {
      profileData: {},

      loginForm: {
        login: '',
        password: '',
      },

      registerForm: {
        email: '',
        taxNumber: '',
      },

      forgotPasswordEmail: '',
      isForgotPassword: false,
      showPassword: false,
      rememberMe: false,

      toggleForm: false,
      innValid: false,
      emailValid: false,

      taxId: "",
      bankId: "",
      phone_number: "",
      inn_checked: false,
      showloader: false,
      companyInfo: {},
      is_signup: false,
      is_teo_contract: 0,
      is_privacy_check: 0,
      is_offer: 0,
      is_personal_data_check: 0,
      error: {
        code: null,
        message: null
      },
      rules: {
        login_required: value => !!value || 'Необходимо заполнить',
        login_counter: value => value.length >= 3 || 'Длина не менее 3 символов',
        pass_required: value => !!value || 'Необходимо заполнить',
        pass_counter: value => value.length >= 5 || 'Длина не менее 5 символов',
        sender_required: value => !!value || 'Необходимо заполнить',
        sender_match: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Похоже, что введенный адрес некорректен...'
        },
      },
      isDisabled: false,
      accessDenied: false
    }
  },

  computed: {
    isFormValid() {
      return (
        this.is_privacy_check &&
        this.is_personal_data_check &&
        this.emailValid &&
        this.innValid > 0
      );
    },
  },

  mounted() {
    const savedLogin = this.$_getsetting('savedLogin')
    if (savedLogin) {
      this.loginForm.login = savedLogin
      this.rememberMe = true
    }
  },

  methods: {
    checkInn() {
      this.error.message = null
      validateInn(this.registerForm.taxNumber, this.error) ? this.innValid = true : this.innValid = false
      if (this.error.code === null) {
        this.checkInnExternally()
      }
    },

    checkInnExternally() {
    const url = this.$api + this.$methods.getcompanybyinn.url + `/${this.registerForm.taxNumber}`

      this.$getapi(url)
        .then(data => {
          if (data.companyName === null) {
            this.error.message = 'Неправильное контрольное число'
            this.error.code = 4
          } else {
            this.error.message = null
            this.error.code = null
          }
        });
    },

    getregisterlink() {
      if (this.registerForm.email.length > 0) {
        this.isDisabled = true
        const uri = this.$api + this.$methods.signup.url

        const params = {
          username: this.registerForm.email,
          inn: this.registerForm.taxNumber,
          referer: document.referrer || '',
          url: window.location.href,
        }

        this.$postapi(uri, params)
        .then(data => {
          if (data.status === "queued") {
            this.$emit('close', 0)
            bus.$emit("show_notify", {
              color: "success",
              notifytext: "Сообщение направлено на указанную почту"
            });
            this.$router.push("/SelfRegistrationMessage")
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
            this.$emit('close', 0)
            this.isDisabled = false
          }
        })
        .catch(error => {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: "Учетные данные уже используются. Пожалуйста, введите другие.",
              duration: 5000
            });
            this.isDisabled = false
          });
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Вы не ввели электронный адрес!"
        });
      }
    },

    validate() {
      if (this.isFormValid === true) {
        this.getregisterlink()
      }
    },

    authNew() {
      this.showloader = true;
      if (this.loginForm.login.length > 0 && this.loginForm.password.length > 0) {
        const uri = this.$api + '/api/v1/signin';
        const params = {
          username: this.loginForm.login,
          password: this.loginForm.password
        };
        const headers = {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          }
        };

        this.$postapi(uri, params, headers)
          .then(data => {
            if (data) {
              this.isUserInn(data);
              this.rememberMeCheck();
              
            } else {
              bus.$emit("show_notify", {
                  color: "error",
                  notifytext: data.message
              });
              this.showloader = false;
            }
          })
          .catch(error => {
            const errorCode = error?.response?.data?.error?.code;
            this.showloader = false;
            if (errorCode) {
              switch (errorCode) {
                case 'invalid_credentials':
                  bus.$emit("show_notify", {
                    color: "error",
                    notifytext: "Неверный логин или пароль. Убедитесь, что вы ввели правильные учетные данные.",
                    duration: 5000
                  });
                  break;
                case 'account_is_locked':
                  this.accessDenied = true;
                  break;
                default:
                  bus.$emit("show_notify", {
                    color: "error",
                    notifytext: "Произошла ошибка при обработке запроса. Пожалуйста, попробуйте еще раз.",
                    duration: 5000
                  });
              }
            } else {
              bus.$emit("show_notify", {
                color: "error",
                notifytext: "Что-то пошло не так. Пожалуйста, повторите попытку.",
                duration: 5000
              });
            }
          });
      } else {
        let message = this.loginForm.login.length < 1 ? "Вы не ввели логин!" : this.loginForm.password.length < 1 ? "Вы не ввели пароль!" : "";
        bus.$emit("show_notify", {
          color: "error",
          notifytext: message
        });
        this.showloader = false
      }
    },

    isUserInn(data) {
      const uri = this.$api + '/api/v1/getUserData'
      const headers = {
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${data.access_token}`,
        }
      };
      this.$postapi(uri, null, headers)
      .then(user => {
        if (user.error == 0) {
          this.profileData = JSON.parse(user.message)
          if (data.client_id === null || Object.prototype.hasOwnProperty.call(this.profileData, 'payers_data') === true) {
            this.$_setsetting('token', data.access_token)
            this.$_setsetting('user_id', data.user_id)
            this.$_setsetting('login', this.loginForm.login)
            this.$_setsetting('email', this.profileData.email)
            this.$_setsetting('client_id', data.client_id === null ? -1 : data.client_id);
            this.$_setsetting('fio', data.fio)
            this.$_setsetting('is_block', this.profileData.is_block)
            this.$_setsetting('is_auth', true)
            this.storeUserData(data.client_id === null ? -1 : data.client_id)
            bus.$emit('auth-update');
            this.$emit('close', 0)

            bus.$emit('updateEmail', this.profileData.email)

          } else {
            this.$_setsetting('token', data.access_token)
            this.$_setsetting('user_id', data.user_id)
            this.$_setsetting('is_block', this.profileData.is_block)
            this.$_setsetting('login', this.loginForm.login)
            this.$_setsetting('client_id', data.client_id)
            if (document.documentElement.clientWidth > 743) {
              this.$router.push('/AddCompanyData')
            }
            this.$emit('close', 0)
          }
          this.showloader = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
          this.showloader = false
        }
      });
    },

    storeUserData(client_id) {
      this.$_setsetting('is_block', this.profileData.is_block)

      if (client_id != -1) {
        this.$_setsetting('is_added_block', this.profileData.is_added_block)
        this.$_setsetting('is_view_orders_block', this.profileData.is_view_orders_block)
        this.$_setsetting('is_view_fin_block', this.profileData.is_view_fin_block)

        if (this.profileData.payers_data[0].accounts_data[0] == null) {
          this.$_setsetting('req_checked', false)
        } else {
          this.$_setsetting('req_checked', true)
        }

        if (this.profileData.client_data[0]) {
          this.$_setsetting('is_show_bm_messages', this.profileData.client_data[0].is_show_bm_messages)

          if (this.profileData.client_data[0].user_email != null) {
            this.$_setsetting('clnt_name', this.profileData.client_data[0].clnt_name)
            this.$_setsetting('manager_email', this.profileData.client_data[0].user_email)
            this.$_setsetting('manager_name', this.profileData.client_data[0].user_name)
            this.$_setsetting('manager_phone', this.profileData.client_data[0].user_phone)
          } else {
            this.$_removesetting('clnt_name')
            this.$_removesetting('manager_email')
            this.$_removesetting('manager_name')
            this.$_removesetting('manager_phone')
          }
        }

      } else {
        this.$_setsetting('req_checked', true)
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    toRegistration() {
      bus.$emit("showloginform", 'signup')
    },

    toLogin() {
      bus.$emit("showloginform", 'login')
    },

    rememberMeCheck(){
      if (this.rememberMe) {
        this.$_setsetting('savedLogin', this.loginForm.login)
      } else {
        this.$_removesetting('savedLogin')
      }
    },

    closeForm(){
      this.$emit('close', false)
    },

    checkRegistrationEmail(){
      this.rules.sender_match(this.registerForm.email) ? this.emailValid = true : this.emailValid = false
    },
  }
}
</script>

<template>
  <div class="login-form">
    <span @click="closeForm" class="login-form__close">
      <img src="@/assets/img/icons/cancel.svg" alt="Закрыть окно">
    </span>
    <div v-if="!accessDenied">
      <div class="login-form__form" v-if="(type === 'login') && !isForgotPassword">
        <h2 class="login-form__title">Вход</h2>
        <form class="login-form__body" @submit.prevent="authNew">
          <AppInput 
            class="login-form__input" 
            label="Логин" 
            v-model.trim="loginForm.login" 
            type="text"
            :rules="[rules.login_required, rules.login_counter]" 
          />
            <div class="login-form__password-input">
              <AppInput 
                class="login-form__input" 
                label="Пароль" 
                v-model.trim="loginForm.password" 
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.pass_required, rules.pass_counter]" 
                @click:append="showPassword = !showPassword"
              />
              <span @click="showPassword = !showPassword" class="login-form__password-input--toggle">
                <img v-if="!showPassword" src="@/assets/img/icons/show-pass-icon.svg" alt="Показать пароль">
                <img v-else src="@/assets/img/icons/hide-pass-icon.svg" alt="Скрыть пароль">
              </span>
            </div>
          <div class="login-form__items">
            <div class="login-form__remember-item">
              <AppCheckbox label="Запомнить меня" v-model="rememberMe" />
            </div>
            <div class="login-form__reset-pass">
              <!-- <a @click="isForgotPassword = true">Забыли пароль?</a>  Скрыто до реализации контракта --> 
            </div>
          </div>
          <AppButton 
            @click="addMetrics('SignIn')" 
            label="Войти" 
            v-if="showloader === false" 
            type="submit" 
          />
          <AppLoader class="login-form__loader" v-else/>
        </form>
      </div>
      <div class="login-form__form" v-else-if="(type === 'signup') && !isForgotPassword">
        <h2 class="login-form__title">Регистрация</h2>
        <form 
          class="login-form__body"
          @submit.prevent="validate"
          ref="form"
        >
          <AppInput
            label="E-mail" 
            v-model="registerForm.email" 
            type="email"
            :rules="[rules.sender_required, rules.sender_match]" 
            @input="checkRegistrationEmail" 
          />
          <AppInput 
            label="ИНН" 
            v-model="registerForm.taxNumber" 
            type="number" 
            @input="checkInn"
            :rules="[() => inn_checked ? '3213' : error.message]"
          />
          <div class="login-form__signup-checkbox-items">
            <div class="login-form__signup-checkbox">
              <AppCheckbox v-model="is_personal_data_check"> 
                <div class="login-form__signup-checkbox-text">
                  Согласен на
                  <router-link :to="'/document/8'" target="_blank">
                    обработку персональных данных
                  </router-link>
                </div>
              </AppCheckbox>
            </div>
            <div class="login-form__signup-checkbox">
              <AppCheckbox v-model="is_privacy_check"> 
                <div class="login-form__signup-checkbox-text">
                  Согласен с условиями
                  <router-link :to="'/document/10'" target="_blank">
                    политики конфидециальности
                  </router-link>
                  и
                  <router-link :to="'/document/9'" target="_blank">
                    пользовательского соглашения
                  </router-link>
                </div>
              </AppCheckbox>
            </div>
          </div>
          <AppButton 
            label="Создать" 
            :disabled="!isFormValid || isDisabled"
            class="login-form__content__sigin__action-btn"
            @click="addMetrics('registration')"
          />

        </form>
      </div>
      <div v-if="!isForgotPassword" class="login-form__bottom">
        <span class="login-form__bottom-word">или</span>
        <span class="login-form__bottom-line"></span>
        <a class="login-form__bottom-btn" v-if="type === 'login'" @click.prevent="toRegistration">Регистрация</a>
        <a class="login-form__bottom-btn" v-else @click.prevent="toLogin">Войти</a>
      </div>
    </div>

    <div class="access-denied" v-else>
      <h2 class="access-denied__title">Доступ заблокирован</h2>
      <p class="access-denied__description">Доступ к вашей учетной записи ограничен системой.</p>
      <p class="access-denied__description">Свяжитесь с вашим персональным менеджером, или обратитесь по общему телефону для уточнения информации </p>
      <a class="access-denied__phone" href="tel:+78005550999">+7 (800) 555-09-99</a>
    </div>


    <!-- Скрыто до реализации контракта  -->
    <!-- <div v-if='isForgotPassword' class="login-form__form">
      <h2 class="login-form__title login-form__title--forgotpass">Восстановить пароль</h2>
      <form class="login-form__body" @submit.prevent="recoverPassword">
        <p class="login-form__body-text">
          Введите адрес электронной почты, который вы указывали при регистрации.
        </p>
        <p class="login-form__body-text">
          Вам будет выслана ссылка для восстановления пароля.
        </p>
        <AppInput label="E-mail" v-model="forgotPasswordEmail" type="email"/>
        <div class="login-form__bottom-btns">
          <AppButton label="Восстановить" />
          <AppButton label="Отмена" color="grey"/>
        </div>
      </form>
    </div> -->

  </div>
</template>

<style scoped lang="scss">
.login-form {
  position: relative;
  max-width: 400px;
  width: 100%;
  padding: 32px;

  border-radius: 8px;
  background: #FFF;

  @media (max-width: $tablet-width) {
    max-height: 520px;
  }

  @media (max-width: 450px){
    padding: 16px 16px 24px 16px;
    max-width: 328px;
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;

    @media (max-width: 450px){
      right: 20px;
      top: 14px;
      max-width: 16px;
      max-height: 16px;
    }
  }

  &__input {
    background-color: unset !important;
  }

  &__title {
    @include font-headline-1;
    color: var(--grey-70);
    margin-bottom: 24px;

    @media (max-width: $mobile-width){
      @include font-headline-2;
    }

    &--forgotpass{
      @include font-body-bold-1;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    color: var(--grey-70);

    @include font-body-3;
  }

  &__remember-item {
    display: flex;
    align-items: center;
  }

  &__reset-pass {
    text-decoration: unset;

    a {
      color: inherit;
    }

  }

  &__signup-checkbox-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__signup-checkbox {
    display: flex;
    align-items: flex-start;

    &-text {
      @include font-description-1;
      color: var(--grey-70);

      a {
        color: inherit;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px 0;
    margin-top: 35px;
    position: relative;

    @include font-body-3;

    @media (max-width: 450px){
      margin-top: 24px;
    }

    &-btn {
      @include font-description-semibold-0;
      color: var(--grey-70);
    }

    &-word {
      @include font-description-1;
      color: var(--grey-50);
      width: 88px;
      text-align: center;
      background-color: #FFF;
      position: relative;
      z-index: 2;
    }

    &-line{
      position: absolute;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid var(--grey-50);
      top: 8px;
    }
  }

  &__bottom-btns{
    display: flex;
  }

  &__body-text{
    margin-bottom: 24px;

    @include font-body-3;
  }

  &__password-input{
    position: relative;

    &--toggle{
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__loader{
    display: block;
    margin: 0 auto;
  }
}

.access-denied{
  color: var(--grey-70);

  &__title{
    @include font-body-bold-1;
  }

  &__description{
    @include font-body-3;
    margin-top: 24px;
  }

  &__phone{
    @include font-body-3;
    color: var(--blue-70);
    text-decoration: unset;
  }
}
</style>
